ol.custom-list {
  counter-reset: list1;
}

.custom-list > li {
  list-style: none;
  position: relative;
  padding-left: 1.5em;
}

.custom-list > li::before {
  counter-increment: list1;
  content: counter(list1) ". ";
  position: absolute;
  left: 0;
}

ol.custom-list ol {
  counter-reset: list2;
}

ol.custom-list ol > li {
  list-style: none;
  position: relative;
  padding-left: 1.5em;
}

ol.custom-list ol > li::before {
  counter-increment: list2;
  content: counter(list2, lower-alpha) ") ";
  position: absolute;
  left: 0;
}

ol.custom-list ol ol {
  counter-reset: list3;
}

ol.custom-list ol ol > li {
  list-style: none;
  position: relative;
  padding-left: 1.5em;
}

ol.custom-list ol ol > li::before {
  counter-increment: list3;
  content: counter(list3, lower-roman) ". ";
  position: absolute;
  left: 0;
}
