/* Root list style */
ol.custom-list {
  counter-reset: list1;
  padding-left: 0em;
  /* margin-top: 1.5em;  */
}

/* First-level list items (e.g., 1, 2, 3) */
.custom-list > li {
  list-style: none;
  position: relative;

  padding-left: 1.8em;
  /* Increase padding between number and text */
  margin-bottom: 0.5em; /* Adjust space between main items (23, 24, etc.) */
}
@media (max-width: 600px) {
  .custom-list > li {
    /* padding-left: 1em; */
    margin-top: 0;
    margin-bottom: 0.3em;
    padding-left: 1.5em; /* Ensure enough space for numbers on mobile */
  }
}
/* First-level list numberings (e.g., 1, 2, 3) */
.custom-list > li::before {
  counter-increment: list1;
  content: counter(list1) '. ';
  position: absolute;
  left: 0;
  /* padding-right: 0.5em; */
  font-weight: bold;
}
@media (max-width: 600px) {
  .custom-list > li::before {
    /* font-size: 1.1em;  */
    /* Adjust font-size for the numbering */
    /* padding-right: 1.2em; */
  }
}
/* Second-level list  (e.g., a, b, c) */
ol.custom-list ol {
  counter-reset: list2;
  padding-left: 0.4em; /* Reduced padding for nested list try 0.8em*/
}
/* Second-level list items (e.g., a, b, c) */
ol.custom-list ol > li {
  list-style: none;
  position: relative;

  padding-left: 1.2em; /* Adjust space between a. and text */
  margin-bottom: 0.2em; /* Reduced space between items */
}
/* Second-level list numberings  (e.g., a, b, c) */
ol.custom-list ol > li::before {
  counter-increment: list2;
  content: counter(list2, lower-alpha) ') ';
  position: absolute;
  left: 0;
}
/* Third-level list  (e.g., i, ii, iii) */
ol.custom-list ol ol {
  counter-reset: list3;
  padding-left: 0.4em; /* Reduced padding for 3rd level, try 1em */
}
/* Third-level list items (e.g., i, ii, iii) */
ol.custom-list ol ol > li {
  list-style: none;
  position: relative;

  padding-left: 1.2em; /* Reduce space between i, ii, iii, and text */
}
@media (max-width: 600px) {
  .custom-list ol ol > li {
    padding-left: 1.6em; /* Adjust nested item spacing on mobile */
  }
}

/* Media query to adjust padding for smaller screens */
/* @media (max-width: 600px) {

  .custom-list ol > li {
    padding-left: 1em;
  }
} */
/* Reduce spacing between paragraphs and list items */
li > p,
li > .MuiTypography-body2 {
  margin-top: 0;
  margin-bottom: 0.3em; /* Reduce space between nested text */
}
/* Media query to adjust padding for smaller screens */
@media (max-width: 600px) {
  .custom-list ol > li {
    padding-left: 1.6em; /* Adjust nested item spacing on mobile */
  }
}
